import React from 'react';
import LoyaltyExperience from './LoyaltyExperience'
import {isIE} from "react-device-detect";


const App = () => {
    if (isIE) {
        return (<div> IE is not supported. Download Chrome/Opera/Firefox </div>)
    }

    return (
        <LoyaltyExperience/>
    );
};

export default App;