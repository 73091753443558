import {Component, useEffect, useRef, useState, Fragment} from "react";
import { useMetaMask } from "metamask-react";
import { fetchRounds, checkRoundWithAccount, fetchRedemption } from "./api.js";
import LoadingSpinner from "./LoadingSpinner";
import { HexColorPicker } from "react-colorful";
import * as PIXI from "pixi.js";
import { saveAs } from 'file-saver';
import * as moment from "moment";

// round did not start yet
const STATE_PENDING = 0;

// round is active
const STATE_ACTIVE  = 1;

// round has ended (either time or because it was won)
const STATE_ENDED   = 2;

let initialCheck = false;

class LoyaltyExperience extends Component {

    constructor() {
        super();

        this.state = {
            baseURI: '',
            currentAccount: 0,

            // settings for the current round
            activeRound: {},
            nextRound: {},
            latestRound: {},
            roundCheck: {},

            redemptionURL: "",

            roundValidating: false,
            isRoundWinner: false,
            wonRoundDetails: {},

            // user data
            userMatches: {
                // list of symbol matches from the current round
                matches: [],
                // flag indicating if we are the round winner
                roundWinner: false
            }
        };
    }

    componentDidMount () {
        fetchRounds().then(
            rounds => {
                this.setState({
                    latestRound: rounds[0].data,
                    activeRound: rounds[1].data,
                    nextRound: rounds[2].data
                });

                if (rounds[1].data && rounds[1].data.redemption) {
                    fetchRedemption(rounds[1].data).then(
                        redemptionURL => {
                            this.setState({
                                redemptionURL: redemptionURL
                            });
                        }
                    )
                }
            }
        );
    }

    setCurrentAccount(account) {
        if (account !== this.state.currentAccount) {
            this.setState({
                currentAccount: account
            });
        }

        if (account) {
            this.setState({
                roundValidating: true
            });

            checkRoundWithAccount(account)
                .then(
                    response => {
                        console.log(response);
                        this.setState({
                            roundCheck:
                                response.message==="success" ?
                                response.data : {}
                        });
                        // store winning results
                        if (response.message === "success" && response.data.is_winner) {
                            this.setState({
                                isRoundWinner: true,
                                wonRoundDetails: response.data.round,
                            });
                        }
                    }
                ).finally(
                () => this.setState({
                        roundValidating: false
                    })
                );
        }
    }

    render() {
        const {
            activeRound,
            nextRound,
            latestRound,
            roundCheck,
            redemptionURL,
            roundValidating,
        } = this.state;

        return (
            <div className='page-root'>
                <div className="framer">
                    <TopMenu />
                    <p className="title">
                        Redemption
                    </p>
                    <p className="subtitle">
                        Own all Maxons with the below traits and verify ownership, to claim the current redemption. The code expires as per the update time below and ownership must be verified before the code updates. NFTs can be claimed once only.
                    </p>

                    <div className="frame-block">
                        <Redemption
                            activeRound={activeRound}
                            nextRound={nextRound}
                            latestRound={latestRound}
                            roundCheck={roundCheck}
                            redemptionURL={redemptionURL}
                            roundValidating={roundValidating}
                            verifyRoundResults={(account) => this.setCurrentAccount(account)}
                        />
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

const Redemption = ({latestRound, activeRound, nextRound, roundCheck, redemptionURL, roundValidating, verifyRoundResults}) => {
    const { status, connect, account } = useMetaMask();

    if (!initialCheck && status === 'connected') {
        verifyRoundResults(account);
        initialCheck = true;
    }

    switch (status) {
        case 'unavailable':
            return <p className="description">
                <b>You haven't installed MetaMask.</b><br/>
                Please go to the official MetaMask website to download it.
            </p>;
        case 'notConnected':
            return <div>
                <button onClick={connect}
                        className="submit-button">Connect
                </button>
            </div>;
        case 'connecting':
        case 'initializing':
            return <LoadingSpinner className="overlay"/>;

        case 'connected':
            return <div>
                {
                    redemptionURL && activeRound && <RedemptionReward
                        imageUrl={redemptionURL}
                        assetUrl={activeRound.redemption}/>
                }

                <RedemptionRound
                    activeRound={activeRound}
                    nextRound={nextRound}
                    latestRound={latestRound}
                    roundCheck={roundCheck}
                    roundValidating={roundValidating}
                    verifyRoundResults={()=>verifyRoundResults(account)}
                />
            </div>;

        default:
            return <div />;
    }
};

const RedemptionReward = ({imageUrl, assetUrl}) => {
    return <div className="redemption">
        <span>Current Redemption (1 in Stock)</span>
        <a href={assetUrl} target="_blank">
            <img src={imageUrl} alt="redemption" />
        </a>
    </div>;
};

const toTitleCase = (str) => {
    return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
};

const RedemptionRound = ({latestRound, activeRound, nextRound, roundCheck, roundValidating, verifyRoundResults}) => {
    const isOwnerMatch = (symbol) => {
        return roundCheck && roundCheck.matched_symbols &&
            roundCheck.matched_symbols.find(
                item => item.emotion === symbol.emotion && item.form === symbol.form
            ) !== undefined;
    };

    if (roundCheck && roundCheck.is_winner === true) {
        return <div>
            <Fragment>
                <div className="redemption">
                    <span>
                        Congratulations, you were first to claim the current round.
                        <br/>
                        See #Redemption-Claims channel at the Maxon Bollocks Discord on How to Claim
                    </span>
                    <p className="subtitle">

                    </p>
                </div>
            </Fragment>
        </div>;
    }

    if (activeRound && activeRound.symbols) {
        return <div>
            <Fragment>
                <ul className="symbols">
                    {activeRound.symbols.map(
                        (symbol, index) => {
                            const OSURL = "https://opensea.io/collection/maxonbollocks?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Form&search[stringTraits][0][values][0]="+
                                toTitleCase(symbol.form)
                                +"&search[stringTraits][1][name]=Emotion&search[stringTraits][1][values][0]="+
                                symbol.emotion;

                            let color = "";
                            try {
                                color = symbol.emotion ? symbol.emotion.split("(")[1].split(")")[0] : "";
                            }   catch (e) {}

                            return <li key={index}>
                                <a href={OSURL} target="_blank">
                                    <img className={isOwnerMatch(symbol) ? "" : "inactive"} src={"/shapes/all/" + (color + " " + symbol.form).toLowerCase() + ".png"}
                                         alt={symbol.emotion + " " + symbol.form}
                                         title={symbol.emotion + " " + symbol.form}
                                    />
                                </a>
                            </li>
                        }
                    )}
                </ul>
                <div className="redemption">
                        <span>
                            Code Updates {moment(activeRound.ends_at*1000).fromNow()}
                        </span>
                    <button onClick={verifyRoundResults} disabled={roundValidating}>Verify Ownership</button>
                </div>
            </Fragment>
        </div>;
    }

    if (nextRound) {
        return <div><Fragment>
            <div className="redemption">
                    <span>
                        Next Code Update {nextRound.started_at ?
                            moment(nextRound.started_at).fromNow() :
                            "coming soon"
                        }
                    </span>
            </div>
        </Fragment></div>;
    }
};

const TopMenu = () => {
    const { status, connect } = useMetaMask();

    return <header>
        <ul className="menu">
            <li className="logo-item">
                <img alt="Maxon Bollocks"
                     className="logo-image"
                     src="https://maxonbollocks.com/wp-content/uploads/2021/07/image_2021_07_20T10_24_41_018Z.png" />
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/abstract/">
                    About
                </a>
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/blog/">
                    Story
                </a>
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/tokenomics/">
                    Tokenomics
                </a>
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/the-art/">
                    The Art
                </a>
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/faq/">
                    FAQ
                </a>
            </li>
        </ul>
        {
            status === 'notConnected' &&
            <button onClick={connect}
                    className="connect-button">Connect
            </button>
        }
        {/*
            status === 'connected' &&
            <span className="account">Welcome, {account}</span>
        */}
    </header>
};

const Footer = () => {
    return <footer>
        <ul className="menu">
            <li className="menu-item">
                <a href="https://maxonbollocks.com/abstract/">
                    About
                </a>
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/blog/">
                    Story
                </a>
            </li>
            <li className="menu-item">
                <a target="_blank" rel="noreferrer" href="https://etherscan.io/address/0xd9aaf0b1733159a4628ecf718a9a3920691891ad#code">
                    Contract Source
                </a>
            </li>
            <li className="menu-item">
                <a href="mailto:support@moba.gallery">
                    Contact
                </a>
            </li>
            <li className="menu-item">
                <a href="https://maxonbollocks.com/terms/">
                    Terms
                </a>
            </li>
        </ul>
        <div className="copyright">
            <span>© 2021 MoBA Ventures Pty Ltd. All rights reserved</span>
            <a target="_blank" rel="noreferrer" href="https://www.twitter.com/MaxonBollocks">
                <img alt="twitter" src="twitter.svg" />
            </a>
        </div>
    </footer>;
};


export default LoyaltyExperience;
