require('dotenv').config();

const ENDPOINT = "https://api.maxonbollocks.com/api/";

/**
 * Before I forget, I wanted to let you know that I finished the back end for the nft game
 available handpoints
 /rounds/active
 /rounds/next
 /rounds/latest
 /rounds/check
 all logic is in rounds/check and there you need to pass the parameter ‘account’ (metamask account and the --address key in your js script)
 I return rounds_symbols, user_symbols, matched_symbols, is_winner
 if is_winner = true - the active round is over
 I also added a dev server supervisor to run the job automatically and finish the round after a lapse of time, if there is no winner
 The only thing you need to fix is timezone, the time is 2 hours late
 If you have any questions about this part, feel free to ask
 */

const ROUND_ACTIVE  = "active";
const ROUND_NEXT    = "next";
const ROUND_LATEST  = "latest";

const fetchRound = async (which) => {
    if ([ROUND_ACTIVE, ROUND_NEXT, ROUND_LATEST].indexOf(which)===-1) {
        return null;
    }

    const response = await fetch(ENDPOINT + "rounds/" + which);

    return await response.json();
};

export const fetchRounds = () => {
    return Promise.all([
        fetchRound(ROUND_LATEST),
        fetchRound(ROUND_ACTIVE),
        fetchRound(ROUND_NEXT),
    ]);

    /*
    return new Promise(resolve => {
        resolve([
            {
                "data": {
                    "id": 4,
                    "symbols_count": 4,
                    "redemption": "https://opensea.io/assets/0xd9aaf0b1733159a4628ecf718a9a3920691891ad/3169",
                    "duration": 24,
                    "is_completed": false,
                    "started_at": "2021-12-29 15:59:00",
                    "finished_at": "2021-12-30 15:59:00",
                    "created_at": "2021-12-29T15:59:14.000000Z",
                    "updated_at": "2021-12-29T15:59:14.000000Z",
                    "ends_at": 1640879940,
                    "symbols": [
                        {
                            "id": 1,
                            "form": "Heart",
                            "emotion": "Strength (Brown)"
                        },
                        {
                            "id": 2,
                            "form": "Oval",
                            "emotion": "Fear (Yellow)"
                        },
                        {
                            "id": 3,
                            "form": "Oval",
                            "emotion": "Envy (Green)"
                        },
                        {
                            "id": 4,
                            "form": "Circle",
                            "emotion": "Nostalgia (Black)"
                        }
                    ]
                },
                "message": "success"
            },
            {
                "data": {
                    "id": 4,
                    "symbols_count": 4,
                    "redemption": "https://opensea.io/assets/0xd9aaf0b1733159a4628ecf718a9a3920691891ad/3169",
                    "duration": 24,
                    "is_completed": false,
                    "started_at": "2021-12-29 15:59:00",
                    "finished_at": "2021-12-30 15:59:00",
                    "created_at": "2021-12-29T15:59:14.000000Z",
                    "updated_at": "2021-12-29T15:59:14.000000Z",
                    "ends_at": 1640879940,
                    "symbols": [
                        {
                            "id": 1,
                            "form": "Heart",
                            "emotion": "Strength (Brown)"
                        },
                        {
                            "id": 2,
                            "form": "Oval",
                            "emotion": "Fear (Yellow)"
                        },
                        {
                            "id": 3,
                            "form": "Oval",
                            "emotion": "Envy (Green)"
                        },
                        {
                            "id": 4,
                            "form": "Circle",
                            "emotion": "Nostalgia (Black)"
                        }
                    ]
                },
                "message": "success"
            },
            {
                "data": [],
                "message": "Record not found."
            }
        ])
    });

    /*
    return Promise.all([
        fetchRound(ROUND_LATEST),
        fetchRound(ROUND_ACTIVE),
        fetchRound(ROUND_NEXT),
    ]);
    */
};

export const fetchRedemption = async (round) => {
    if (round && round.redemption) {
        const pieces = round.redemption.split("assets/");
        if (pieces.length === 2) {
            const response = await fetch("https://api.opensea.io/api/v1/asset/" + pieces[1]);
            if (response) {
                const data = await response.json();
                return data.image_url;
            }
        }
    }
};

// https://api.opensea.io/api/v1/asset/0xd9aaf0b1733159a4628ecf718a9a3920691891ad/3169

export const checkRoundWithAccount = async (account) => {
    const response = await fetch(ENDPOINT + "rounds/check?account=" + account);

    return await response.json();

    /*
    return new Promise(resolve => {
        resolve({
            "data": {
                "round": {
                    "id": 4,
                    "symbols_count": 4,
                    "redemption": "https://opensea.io/assets/0xd9aaf0b1733159a4628ecf718a9a3920691891ad/3169",
                    "duration": 24,
                    "is_completed": false,
                    "started_at": "2021-12-29 15:59:00",
                    "finished_at": "2021-12-30 15:59:00",
                    "created_at": "2021-12-29T15:59:14.000000Z",
                    "updated_at": "2021-12-29T15:59:14.000000Z",
                    "symbols": [
                        {
                            "id": 1,
                            "form": "Heart",
                            "emotion": "Strength (Brown)"
                        },
                        {
                            "id": 2,
                            "form": "Oval",
                            "emotion": "Fear (Yellow)"
                        },
                        {
                            "id": 3,
                            "form": "Oval",
                            "emotion": "Envy (Green)"
                        },
                        {
                            "id": 4,
                            "form": "Circle",
                            "emotion": "Nostalgia (Black)"
                        }
                    ]
                },
                "round_symbols": [
                    {
                        "id": 1,
                        "form": "Heart",
                        "emotion": "Strength (Brown)"
                    },
                    {
                        "id": 2,
                        "form": "Oval",
                        "emotion": "Fear (Yellow)"
                    },
                    {
                        "id": 3,
                        "form": "Oval",
                        "emotion": "Envy (Green)"
                    },
                    {
                        "id": 4,
                        "form": "Circle",
                        "emotion": "Nostalgia (Black)"
                    }
                ],
                "user_symbols": [
                    {
                        "filename": "1311",
                        "form": "Oval",
                        "emotion": "Envy (Green)"
                    },
                    {
                        "filename": "1309",
                        "form": "Diamond",
                        "emotion": "Envy (Green)"
                    },
                    {
                        "filename": "1913",
                        "form": "Rectangle",
                        "emotion": "Calm (Blue)"
                    },
                    {
                        "filename": "1914",
                        "form": "Circle",
                        "emotion": "Calm (Blue)"
                    },
                    {
                        "filename": "1916",
                        "form": "Triangle",
                        "emotion": "Nostalgia (Black)"
                    },
                    {
                        "filename": "1918",
                        "form": "Oval",
                        "emotion": "Calm (Blue)"
                    },
                    {
                        "filename": "1922",
                        "form": "Star",
                        "emotion": "Lust (Red)"
                    },
                    {
                        "filename": "1923",
                        "form": "Oval",
                        "emotion": "Nostalgia (Black)"
                    },
                    {
                        "filename": "1927",
                        "form": "Oval",
                        "emotion": "Purity (White)"
                    },
                    {
                        "filename": "1926",
                        "form": "Star",
                        "emotion": "Nostalgia (Black)"
                    },
                    {
                        "filename": "1928",
                        "form": "Square",
                        "emotion": "Nostalgia (Black)"
                    },
                    {
                        "filename": "1940",
                        "form": "Rectangle",
                        "emotion": "Anxiety (Grey)"
                    },
                    {
                        "filename": "1930",
                        "form": "Heart",
                        "emotion": "Envy (Green)"
                    },
                    {
                        "filename": "1932",
                        "form": "Square",
                        "emotion": "Anxiety (Grey)"
                    },
                    {
                        "filename": "1934",
                        "form": "Star",
                        "emotion": "Passion (Purple)"
                    },
                    {
                        "filename": "1936",
                        "form": "Star",
                        "emotion": "Nostalgia (Black)"
                    },
                    {
                        "filename": "1943",
                        "form": "Heart",
                        "emotion": "Curiosity (Orange)"
                    },
                    {
                        "filename": "1944",
                        "form": "Triangle",
                        "emotion": "Anxiety (Grey)"
                    },
                    {
                        "filename": "1945",
                        "form": "Heart",
                        "emotion": "Suprise (Pink)"
                    },
                    {
                        "filename": "1946",
                        "form": "Heart",
                        "emotion": "Calm (Blue)"
                    },
                    {
                        "filename": "1949",
                        "form": "Oval",
                        "emotion": "Purity (White)"
                    }
                ],
                "matched_symbols": [
                    {
                        "id": 1,
                        "form": "Heart",
                        "emotion": "Strength (Brown)"
                    },
                    {
                        "id": 2,
                        "form": "Oval",
                        "emotion": "Fear (Yellow)"
                    },
                    {
                        "filename": "1311",
                        "form": "Oval",
                        "emotion": "Envy (Green)",
                        "id": 3
                    },
                    {
                        "id": 4,
                        "form": "Circle",
                        "emotion": "Nostalgia (Black)"
                    }
                ],
                "is_winner": false
            },
            "message": "success"
        })
    });
     */
};